var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex my-16",class:( _obj = {
    'flex-column': _vm.windowWidth <= 600
  }, _obj[_vm.getClassName] = _vm.windowWidth > 600, _obj )},_vm._l((_vm.buttons),function(button,index){return _c('v-btn',{key:index,staticClass:"ml-5 default-btn mx-8",class:{
      active: _vm.selected === button,
      'mt-5': _vm.windowWidth <= 600 && index > 0,
    },attrs:{"outlined":"","text":""},on:{"click":function($event){return _vm.selectedButton(button)}}},[_vm._v(_vm._s(_vm.$t(("buttons." + button))))])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }