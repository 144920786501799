<template>
  <div
    class="d-flex my-16"
    :class="{
      'flex-column': windowWidth <= 600,
      [getClassName]: windowWidth > 600,
    }"
  >
    <v-btn
      v-for="(button, index) in buttons"
      :key="index"
      @click="selectedButton(button)"
      class="ml-5 default-btn mx-8"
      :class="{
        active: selected === button,
        'mt-5': windowWidth <= 600 && index > 0,
      }"
      outlined
      text
      >{{ $t(`buttons.${button}`) }}</v-btn
    >
  </div>
</template>
<script>
import windowWidth from "@/mixins/windowWidth";

export default {
  mixins: [windowWidth],
  props: {
    position: {
      type: String,
      default: "center",
    },

    buttons: {
      type: Array,
      default: () => ["newVoting", "votingInProgress"],
    },
  },
  data() {
    return {
      showVotingCards: true,
      selected: this.buttons[0],
    };
  },

  methods: {
    selectedButton(value) {
      /*let showVoting = false;
      if () {
        showVoting = true;
      }*/

      this.selected = value;
      this.$emit("showVoting", value === this.buttons[1]);
      this.$emit("selected", value);
    },
  },

  watch: {},

  computed: {
    getClassName() {
      const { position } = this;
      return `justify-${position}`;
    },

    changeSwitcher() {
      return this.$store.state.changeSwitcher;
    },
  },
};
</script>
<style lang="scss" scoped>
.active {
  background: #6200ee;
  color: white !important;
  border: transparent !important;
}

.default-btn {
  min-width: 220px !important;
  height: 56px !important;
}
</style>
